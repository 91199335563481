export const ALL_SCRAP_PRODUCT_REQUEST = "ALL_SCRAP_PRODUCT_REQUEST";
export const ALL_SCRAP_PRODUCT_SUCCESS = "ALL_SCRAP_PRODUCT_SUCCESS";
export const ALL_SCRAP_PRODUCT_FAIL = "ALL_SCRAP_PRODUCT_FAIL";

export const ADMIN_SCRAP_PRODUCT_REQUEST = "ADMIN_SCRAP_PRODUCT_REQUEST";
export const ADMIN_SCRAP_PRODUCT_SUCCESS = "ADMIN_SCRAP_PRODUCT_SUCCESS";
export const ADMIN_SCRAP_PRODUCT_FAIL = "ADMIN_SCRAP_PRODUCT_FAIL";

export const NEW_SCRAP_PRODUCT_REQUEST = "NEW_SCRAP_PRODUCT_REQUEST";
export const NEW_SCRAP_PRODUCT_SUCCESS = "NEW_SCRAP_PRODUCT_SUCCESS";
export const NEW_SCRAP_PRODUCT_RESET = "NEW_SCRAP_PRODUCT_RESET";
export const NEW_SCRAP_PRODUCT_FAIL = "NEW_SCRAP_PRODUCT_FAIL";

export const UPDATE_SCRAP_PRODUCT_REQUEST = "UPDATE_SCRAP_PRODUCT_REQUEST";
export const UPDATE_SCRAP_PRODUCT_SUCCESS = "UPDATE_SCRAP_PRODUCT_SUCCESS";
export const UPDATE_SCRAP_PRODUCT_RESET = "UPDATE_SCRAP_PRODUCT_RESET";
export const UPDATE_SCRAP_PRODUCT_FAIL = "UPDATE_SCRAP_PRODUCT_FAIL";

export const DELETE_SCRAP_PRODUCT_REQUEST = "DELETE_SCRAP_PRODUCT_REQUEST";
export const DELETE_SCRAP_PRODUCT_SUCCESS = "DELETE_SCRAP_PRODUCT_SUCCESS";
export const DELETE_SCRAP_PRODUCT_RESET = "DELETE_SCRAP_PRODUCT_RESET";
export const DELETE_SCRAP_PRODUCT_FAIL = "DELETE_SCRAP_PRODUCT_FAIL";

export const PRODUCT_SCRAP_DETAILS_REQUEST = "PRODUCT_SCRAP_DETAILS_REQUEST";
export const PRODUCT_SCRAP_DETAILS_SUCCESS = "PRODUCT_SCRAP_DETAILS_SUCCESS";
export const PRODUCT_SCRAP_DETAILS_FAIL = "PRODUCT_SCRAP_DETAILS_FAIL";

export const NEW_REVIEW_REQUEST = "NEW_REVIEW_REQUEST";
export const NEW_REVIEW_SUCCESS = "NEW_REVIEW_SUCCESS";
export const NEW_REVIEW_RESET = "NEW_REVIEW_RESET";
export const NEW_REVIEW_FAIL = "NEW_REVIEW_FAIL";

export const ALL_REVIEW_REQUEST = "ALL_REVIEW_REQUEST";
export const ALL_REVIEW_SUCCESS = "ALL_REVIEW_SUCCESS";
export const ALL_REVIEW_FAIL = "ALL_REVIEW_FAIL";

export const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST";
export const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
export const DELETE_REVIEW_RESET = "DELETE_REVIEW_RESET";
export const DELETE_REVIEW_FAIL = "DELETE_REVIEW_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
